/*
    Project Name : Business
    Author Company : SpecThemes
    Project Date: 5 Feb, 2017
    Template Developer: vsafaryan50@gmail.com
*/

/*
==============================================
TABLE OF CONTENT
==============================================

   Preloader
1. Owl Carousels
2. CountUp
3. Slider
4. Hover Drop Down
5. Youtube Video Section
6. Video Modal
7. Shopping Cart
8. Scroll To Top
9. Isotop
10. WOW
11. Serach
12. Input Number
13. Tabs
14. Pie Chart

==============================================
[END] TABLE OF CONTENT
==============================================
*/

$('#preloader').fadeOut('normall', function () {
  $(this).remove();
});

"use strict";

$(document).ready(function () {

  /*------------------------------------
      1. Owl Carousel
  --------------------------------------*/

  /*---------------------
  Testmonials carousel
  -----------------------*/

  $('#testmonials-carousel').owlCarousel({
    loop: false,
    responsiveClass: true,
    nav: true,
    navText: ["<i class='fa fa-angle-left'></i>", "<i class='fa fa-angle-right'></i>"],
    responsive: {
      0: {
        items: 1,
        nav: false,
        dots: true,
        margin: 10
      },
      600: {
        items: 1,
        nav: false,
        dots: true,
        margin: 15
      },
      1000: {
        items: 1,
        dots: false,
        margin: 40
      }
    }
  });

  /*---------------------
  Services carousel
  -----------------------*/

  $('#services-carousel').owlCarousel({
    loop: false,
    responsiveClass: true,
    dots: false,
    nav: true,
    navText: ["<i class='fa fa-angle-left'></i>", "<i class='fa fa-angle-right'></i>"],
    responsive: {
      0: {
        items: 1,
        margin: 15
      },
      600: {
        items: 2,
        margin: 30
      },
      1000: {
        items: 3,
        margin: 0
      }
    }
  });

  /*---------------------
  Blog Grid carousel
  -----------------------*/
  $('#blog-grid').owlCarousel({
    loop: false,
    dots: true,
    nav: false,
    autoplay: true,
    autoplayTimeout: 2500,
    responsiveClass: true,
    autoplayHoverPause: false,
    responsive: {
      0: {
        items: 1,
        margin: 0
      },
      600: {
        items: 2,
        margin: 0
      },
      1000: {
        items: 3,
        margin: 0
      }
    }
  });

  /*---------------------
  Clients carousel
  -----------------------*/

  $('#clients').owlCarousel({
    loop: true,
    nav: false,
    dots: false,
    autoplay: true,
    autoplayTimeout: 3000,
    responsiveClass: true,
    autoplayHoverPause: false,
    responsive: {
      0: {
        items: 2,
        margin: 50
      },
      600: {
        items: 3,
        margin: 30
      },
      1000: {
        items: 6,
        margin: 40
      }
    }
  });

  /*---------------------
  Project Single carousel
  -----------------------*/
  $('#project-single').owlCarousel({
    loop: false,
    nav: false,
    responsiveClass: true,
    dots: false,
    responsive: {
      0: {
        items: 1,
        margin: 15
      },
      600: {
        items: 2,
        margin: 15
      },
      1000: {
        items: 3,
        margin: 15
      }
    }
  });

  /*------------------------------------
      2. CountUp
  --------------------------------------*/

  $('.countup').counterUp({
    delay: 25,
    time: 2000
  });

  /*------------------------------------
      3. Slider
  --------------------------------------*/

  /*---------------------
  Main Slider
  -----------------------*/
  if ($(".swiper-main-slider").length !== 0) {
    //Slider Animated Caption 
    var swiper = new Swiper('.swiper-container', {
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev'
      },
      pagination: {
        el: '.swiper-pagination',
        dynamicBullets: true
      },
      pagination: '.swiper-pagination',
      paginationClickable: true,
      nextButton: '.swiper-button-next',
      prevButton: '.swiper-button-prev',
      spaceBetween: 0,
      loop: true,
      simulateTouch: true,
      autoplay: 5000,
      speed: 1000,
      onSlideChangeEnd: function (swiper) {
        $('.swiper-slide').each(function () {
          if ($(this).index() === swiper.activeIndex) {
            // Fadein in active slide
            $(this).find('.slider-content').fadeIn(300);
          } else {
            // Fadeout in inactive slides
            $(this).find('.slider-content').fadeOut(300);
          }
        });
      }
    });
  }

  /*---------------------
  Main Slider Fade Effect
  -----------------------*/
  if ($(".swiper-main-slider-fade").length !== 0) {
    //Slider Animated Caption 
    var swiper = new Swiper('.swiper-container', {
      effect: 'fade',
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev'
      },
      pagination: {
        el: '.swiper-pagination',
        dynamicBullets: true
      },
      pagination: '.swiper-pagination',
      paginationClickable: true,
      nextButton: '.swiper-button-next',
      prevButton: '.swiper-button-prev',
      spaceBetween: 0,
      loop: true,
      simulateTouch: true,
      autoplay: 5000,
      speed: 1000,
      onSlideChangeEnd: function (swiper) {
        $('.swiper-slide').each(function () {
          if ($(this).index() === swiper.activeIndex) {
            // Fadein in active slide
            $(this).find('.slider-content').fadeIn(300);
          } else {
            // Fadeout in inactive slides
            $(this).find('.slider-content').fadeOut(300);
          }
        });
      }
    });
  }

  /*---------------------
  Parallax Slider
  -----------------------*/
  if ($("#swiper-parallax").length !== 0) {
    var swiper = new Swiper('.swiper-container', {
      parallax: true,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev'
      },
      pagination: {
        el: '.swiper-pagination',
        dynamicBullets: true
      },
      pagination: '.swiper-pagination',
      paginationClickable: true,
      nextButton: '.swiper-button-next',
      prevButton: '.swiper-button-prev',
      spaceBetween: 0,
      loop: false,
      simulateTouch: true,
      autoplay: false,
      speed: 1000
    });
  }

  /*---------------------
  Revolution Slider
  -----------------------*/
  if ($("#rev_slider_24_1").length !== 0) {
    var tpj = jQuery;
    var revapi24;
    tpj(document).ready(function () {
      if (tpj("#rev_slider_24_1").revolution == undefined) {
        revslider_showDoubleJqueryError("#rev_slider_24_1");
      } else {
        revapi24 = tpj("#rev_slider_24_1").show().revolution({
          sliderType: "standard",
          jsFileLocation: "revolution/js/",
          sliderLayout: "fullscreen",
          dottedOverlay: "none",
          delay: 9000,
          navigation: {
            keyboardNavigation: "off",
            keyboard_direction: "horizontal",
            mouseScrollNavigation: "off",
            mouseScrollReverse: "default",
            onHoverStop: "off",
            bullets: {
              enable: true,
              hide_onmobile: false,
              style: "bullet-bar",
              hide_onleave: false,
              direction: "horizontal",
              h_align: "center",
              v_align: "bottom",
              h_offset: 0,
              v_offset: 50,
              space: 5,
              tmp: ''
            }
          },
          responsiveLevels: [1240, 1024, 778, 480],
          visibilityLevels: [1240, 1024, 778, 480],
          gridwidth: [1240, 1024, 778, 480],
          gridheight: [868, 768, 960, 720],
          lazyType: "none",
          shadow: 0,
          spinner: "off",
          stopLoop: "off",
          stopAfterLoops: -1,
          stopAtSlide: -1,
          shuffle: "off",
          autoHeight: "off",
          fullScreenAutoWidth: "off",
          fullScreenAlignForce: "off",
          fullScreenOffsetContainer: "",
          fullScreenOffset: "60px",
          hideThumbsOnMobile: "off",
          hideSliderAtLimit: 0,
          hideCaptionAtLimit: 0,
          hideAllCaptionAtLilmit: 0,
          debugMode: false,
          fallbacks: {
            simplifyAll: "off",
            nextSlideOnWindowFocus: "off",
            disableFocusListener: false
          }
        });
      }

      if (revapi24) revapi24.revSliderSlicey();
    }); /*ready*/
  }

  /*------------------------------------
      4. Navbar
  --------------------------------------*/

  /* Scroll To */
  function scroll_to(el) {
    var headerHeight,
        scrollPosition,
        elementId = $(el).attr('href');

    if ($(window).width() > 1200) {
      headerHeight = $('header').height();
    } else {
      headerHeight = $('navbar-header').height();
    };

    scrollPosition = $(elementId).offset().top - headerHeight;

    // scroll to
    $('body,html').animate({
      scrollTop: scrollPosition
    }, 800);

    return false;
  }

  function scroll_navbar_updater() {
    // Cache selectors
    var lastId,
        topMenu = $("header"),
        topMenuHeight = topMenu.outerHeight() + 15,

    // All list items
    menuItems = topMenu.find("a"),

    // Anchors corresponding to menu items
    scrollItems = menuItems.map(function () {
      var item = $($(this).attr("href"));
      if (item.length) {
        return item;
      }
    });

    // Bind to scroll
    $(window).scroll(function () {
      // Get container scroll position
      var fromTop = $(this).scrollTop() + topMenuHeight;

      // Get id of current scroll item
      var cur = scrollItems.map(function () {
        if ($(this).offset().top < fromTop) return this;
      });

      // Get the id of the current element
      cur = cur[cur.length - 1];

      var id = cur && cur.length ? cur[0].id : "";

      if (lastId !== id) {
        lastId = id;
        // Set/remove active class
        menuItems.parent().removeClass("active").end().filter("[href='#" + id + "']").parent().addClass("active");
      }
    });
  }

  $('.navbar-nav a').on('click', function () {
    var $this = $(this);

    $('navbar-nav li').removeClass('active');
    $this.parent().addClass('active');

    scroll_to(this);

    return false;
  });

  $('.button-md').on('click', function () {
    scroll_to(this);
    return false;
  });

  $('#contact-us').on('click', function () {
    var headerHeight = $('header').height();
    scrollPosition = $('#form-section').offset().top - headerHeight;

    $('body,html').animate({
      scrollTop: scrollPosition
    }, 800);

    return false;
  });

  scroll_navbar_updater();

  /*------------------------------------
      5. Youtube Video Section
  --------------------------------------*/
  if ($(".video-section").length !== 0) {
    $('.player').mb_YTPlayer();
  }

  if ($(".main-video-section").length !== 0) {
    $('#main-video-play').mb_YTPlayer();
  }

  /*------------------------------------
      6. Video Modal
  --------------------------------------*/

  $('.modal').on('hidden.bs.modal', function () {
    var $this = $(this).find('iframe'),
        tempSrc = $this.attr('src');
    $this.attr('src', "");
    $this.attr('src', tempSrc);
  });

  /*------------------------------------
      7. Shopping Cart
  --------------------------------------*/
  $('.close-box').on("click", function () {
    $(this).parentsUntil('.row').slideToggle();
    return false;
  });

  /*------------------------------------
      8. Scroll To Top
  --------------------------------------*/

  $(window).scroll(function () {
    if ($(this).scrollTop() > 500) {
      $(".scroll-to-top").fadeIn(400);
    } else {
      $(".scroll-to-top").fadeOut(400);
    }
  });

  $(".scroll-to-top").on('click', function (event) {
    event.preventDefault();
    $("html, body").animate({ scrollTop: 0 }, 600);
  });

  /*------------------------------------
      Contact Form 
  --------------------------------------*/

  $('.contact-form').submit(function (e) {
    e.preventDefault();
    var flag = 0;
    var count = 0;
    var $form = $(this);
    $('.require').each(function () {
      if ($.trim($(this).val()) == '') {
        $(this).css({ 'border': '1px solid red' });
        flag = 0;
      } else {
        $(this).css({ 'border': '1px solid #f6f7f8' });
        count += 1;
      }
    });

    if (count >= 4) {
      flag = 1;
    }

    if (flag == 1) {
      $.ajax({
        type: $form.attr('method'),
        url: 'https://mailer.ubiwhere.com/mailer.php',
        data: $form.serialize()
      });

      $('.require').each(function () {
        $(this).val('');
      });

      $('.confirmation').fadeIn();
    } else {
      $('.confirmation').fadeOut();
    }
  });

  /*------------------------------------
      9. Isotop
  --------------------------------------*/

  // external js: isotope.pkgd.js
  // init Isotope
  var $grid = $('.isotope-grid').isotope({
    itemSelector: '.isotope-item',
    layoutMode: 'fitRows',
    getSortData: {
      name: '.name',
      symbol: '.symbol',
      number: '.number parseInt',
      category: '[data-category]',
      weight: function (itemElem) {
        var weight = $(itemElem).find('.weight').text();
        return parseFloat(weight.replace(/[\(\)]/g, ''));
      }
    }
  });

  // filter functions
  var filterFns = {
    // show if name ends with -ium
    ium: function () {
      var name = $(this).find('.name').text();
      return name.match(/ium$/);
    }
  };

  // bind filter button click
  $('#filters').on('click', 'button', function () {
    var filterValue = $(this).attr('data-filter');
    // use filterFn if matches value
    filterValue = filterFns[filterValue] || filterValue;
    $grid.isotope({ filter: filterValue });
  });

  // change is-checked class on buttons
  $('.parent-isotope').each(function (i, buttonGroup) {
    var $buttonGroup = $(buttonGroup);
    $buttonGroup.on('click', 'button', function () {
      $buttonGroup.find('.is-checked').removeClass('is-checked');
      $(this).addClass('is-checked');
    });
  });

  /*------------------------------------
      10. WOW
  --------------------------------------*/
  new WOW().init();
});

/*------------------------------------
    11. Seacrh
--------------------------------------*/
function openNav() {
  document.getElementById("search_nav").style.width = "100%";
}

function closeNav() {
  document.getElementById("search_nav").style.width = "0%";
}

/*------------------------------------
    12. Input Number, Shopping Cart
--------------------------------------*/

/*---------------------
Input Number
-----------------------*/
jQuery('<div class="quantity-nav"><div class="quantity-button quantity-up">+</div><div class="quantity-button quantity-down">-</div></div>').insertAfter('.quantity input');
jQuery('.quantity').each(function () {
  var spinner = jQuery(this),
      input = spinner.find('input[type="number"]'),
      btnUp = spinner.find('.quantity-up'),
      btnDown = spinner.find('.quantity-down'),
      min = input.attr('min'),
      max = input.attr('max');

  btnUp.on("click", function () {
    var oldValue = parseFloat(input.val());
    if (oldValue >= max) {
      var newVal = oldValue;
    } else {
      var newVal = oldValue + 1;
    }
    spinner.find("input").val(newVal);
    spinner.find("input").trigger("change");
  });

  btnDown.on("click", function () {
    var oldValue = parseFloat(input.val());
    if (oldValue <= min) {
      var newVal = oldValue;
    } else {
      var newVal = oldValue - 1;
    }
    spinner.find("input").val(newVal);
    spinner.find("input").trigger("change");
  });
});

/*------------------------------------
    13. Tabs
--------------------------------------*/

$('.tabs_animate').tabslet({
  mouseevent: 'click',
  attribute: 'href',
  animation: true
});

/*------------------------------------
    14. Pie Chart
--------------------------------------*/
if ($(".chartjs-render-monitor").length !== 0) {
  var randomScalingFactor = function () {
    return Math.round(Math.random() * 100);
  };

  var config = {
    type: 'pie',
    data: {
      datasets: [{
        data: [randomScalingFactor(), randomScalingFactor(), randomScalingFactor(), randomScalingFactor()],
        backgroundColor: [window.chartColors.red, window.chartColors.orange, window.chartColors.green, window.chartColors.blue],
        label: 'Dataset 1'
      }],
      labels: ["Red", "Orange", "Green", "Blue"]
    },
    options: {
      responsive: true
    }
  };

  window.onload = function () {
    var ctx = document.getElementById("chart-area").getContext("2d");
    window.myPie = new Chart(ctx, config);
  };

  document.getElementById('randomizeData').addEventListener('click', function () {
    config.data.datasets.forEach(function (dataset) {
      dataset.data = dataset.data.map(function () {
        return randomScalingFactor();
      });
    });

    window.myPie.update();
  });

  var colorNames = Object.keys(window.chartColors);
  document.getElementById('addDataset').addEventListener('click', function () {
    var newDataset = {
      backgroundColor: [],
      data: [],
      label: 'New dataset ' + config.data.datasets.length
    };

    for (var index = 0; index < config.data.labels.length; ++index) {
      newDataset.data.push(randomScalingFactor());

      var colorName = colorNames[index % colorNames.length];;
      var newColor = window.chartColors[colorName];
      newDataset.backgroundColor.push(newColor);
    }

    config.data.datasets.push(newDataset);
    window.myPie.update();
  });

  document.getElementById('removeDataset').addEventListener('click', function () {
    config.data.datasets.splice(0, 1);
    window.myPie.update();
  });
}